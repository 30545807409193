<template>
  <div class="head-menu">
    <el-menu :default-active="$route.path" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff"
      active-text-color="#ffd04b">
      <MenuItem v-for="(route, index) in routersList" :key="index" :item="route" :fatherPath="route.path">
      </MenuItem>
    </el-menu>

    <div class="header-main">
      <el-button @click="loginOut" class="loginOutBtn" type="primary">Log Out</el-button>
    </div>
  </div>
</template>

<script>
import routers from "../../../router";
import MenuItem from "./MenuItem.vue";
export default {
  components: {
    MenuItem,
  },
  data () {
    return {
      routersList: [],
    };
  },
  mounted () {
    // 获取所有定义的一级菜单和多级菜单
    this.routersList = routers.options.routes[0].children;
  },
  methods: {
        loginOut() {
            localStorage.setItem("token", null);
            window.location.href = "/login";
        },
    },
};
</script>
<style lang="less" scoped>
.head-menu {
  position: relative;

  .header-main {
    position: absolute;
    bottom: 10vh;
    left: 20%;
  }

  .header-main {
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 3vh;
    left: 0;
    text-align: center;
    box-sizing: border-box;
    .el-button{
      width: 96%;
    }
  }
}
</style>>